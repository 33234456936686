import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../theme/theme.yaml";
import { MDXRenderer } from "gatsby-plugin-mdx";

import config from "../../../content/meta/config";
import avatar from "../../images/jpg/avatar.jpg";

const Author = (props) => {
  const { note } = props;
  // console.log("author props: ", props);

  return (
    <StyledFragment>
      <div className="author">
        <div className="avatar">
          <img
            src={config.gravatarImgMd5 == "" ? avatar : config.gravatarImgMd5}
            alt={config.siteTitle}
          />
        </div>
        <strong>Jeremy Mauboussin</strong> Founder of PlagiaShield. Marketing Technologist. Trail runner
        {/* <MDXRenderer className="note">{note}</MDXRenderer> */}
        {/* <div className="note" dangerouslySetInnerHTML={{ __html: note }} /> */}
      </div>
    </StyledFragment>
  );
};

Author.propTypes = {
  note: PropTypes.string.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default Author;

const StyledFragment = styled.div`
  .author {
    margin: ${theme.space.l} 0;
    padding: ${theme.space.l} 0;
    border-top: 1px solid ${theme.line.color};
    border-bottom: 1px solid ${theme.line.color};
  }
  .avatar {
    float: left;
    border-radius: 65% 75%;
    border: 1px solid ${theme.line.color};
    display: inline-block;
    height: 50px;
    margin: 5px 20px 0 0;
    overflow: hidden;
    width: 50px;
  }
  .avatar img {
    width: 100%;
  }
  .note {
    font-size: 0.9em;
    line-height: 1.6;
  }
  @media screen and (min-width: 700px) {
    .author {
      display: flex;
    }
    .avatar {
      flex: 0 0 auto;
    }
  }
`;
