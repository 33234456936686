import React from "react";
import PropTypes from "prop-types";
// import theme from "../../theme/theme.yaml";

// import "prismjs/themes/prism-okaidia.css";

import asyncComponent from "../AsyncComponent";
import Headline from "../Article/Headline";
import Bodytext2 from "../Article/Bodytext2";
// import Meta from "./Meta";
import Author from "./Author";
// import Comments from "./Comments";
import NextPrev from "./NextPrev";

const Share = asyncComponent(() =>
  import("./Share")
    .then((module) => {
      return module.default;
    })
    .catch((error) => { })
);

const Post = (props) => {
  const {
    post,
    post: {
      body,
      fields: { prefix, slug },
      frontmatter: { title, author, category },
    },
    authornote,
    facebook,
    next: nextPost,
    prev: prevPost,
  } = props;

  return (
    <React.Fragment>
      <header>
        <Headline title={title} />
        {/* <Meta prefix={prefix} author={author} category={category} theme={theme} /> */}
      </header>
      <Bodytext2>
        {props.children}
      </Bodytext2>
      <footer>
        <Share post={post} />
        <Author />
        <NextPrev next={nextPost} prev={prevPost} />
        {/* <Comments slug={slug} facebook={facebook} /> */}
      </footer>
    </React.Fragment>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  // authornote: PropTypes.string.isRequired,
  facebook: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  // theme: PropTypes.object.isRequired,
};

export default Post;
